<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <!--        表格-->
        <statistics-table></statistics-table>
      </div>
    </PageHeaderLayout>

  </div>
</template>

<script>
import StatisticsTable from './StatisticsTable'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import AdIncome from '@/pages/revenue/order/AdIncome'

export default {
  name: 'OrderRank',
  components: {
    AdIncome,
    StatisticsTable, PageHeaderLayout
  },
  data() {
    return {
      searchCondition: { ym: null }
    }
  },
  methods: {
    // defaultTime() {
    //   const end = new Date();
    //   //年份选择：默认当月
    //   this.searchCondition.ym = end.getFullYear() + '-' + (end.getMonth() + 1)
    // },
  },
  created() {
    this.defaultTime()
  }
}
</script>

<style scoped>

</style>
